import React , {useContext, useState, useEffect} from "react";
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext";
import { kutuseGrafikTable } from './functions/kutuseGrafikTable'


export function KutuseGrafik(){
    const { token } = useContext(AuthContext)
    const { request } = useHttp()
    const [nr, setNr] = useState(null)
    const [hidden, setHidden] = useState(false)

    const getHandler = async() =>{
      let year = document.getElementById('year').value
      let month = document.getElementById('month').value 
        try{
          const data = await request('/api/kutus/getall', 'POST', {year, month},{
            Authorization: `Bearer ${token}`
          })          
          if(data){setHidden(true)}
          if(data){kutuseGrafikTable(data)}
        } catch(e){
            alert(e.message) 
      } 
    }
    function sort(nr){
      let tBodies = document.querySelector('tbody')
      let tr = document.querySelectorAll('tr')
      let sortedRows = Array.from(tr)
      .slice(1)
      .sort((rowA, rowB) => rowA.cells[nr].innerHTML > rowB.cells[nr].innerHTML ? 1 : -1);
      tBodies.append(...sortedRows);
    }
    useEffect(()=>{
      let selection = document.getElementById('selection')
      if(nr != null)sort(nr)
      if(!hidden){selection.style.display = 'none'}
      if(hidden){selection.style.display = ''}
    },[nr, hidden])
    function change(){
      let sortSelection = document.getElementById('kutus').value
      setNr(sortSelection)
    }
    return(
    <>
    <div className="registerInputs inputsDiv">
          <input placeholder="aasta" 
                 className='authPageInput'
                 type= "number"
                 id="year" />
          <input placeholder="kuu"
                 className='authPageInput'
                 type= "number"
                 id="month" />
        <div className="buttonDiv">
          <button onClick={getHandler} className="push_button">Näita</button>
        </div>  
    </div>
          <div className="selection" id="selection">
            <h5 className="h5">sorteeri</h5>
              <select onChange={change} className="kutus authPageInput" id="kutus"> 
                <option value="null"></option>
                <option value="0">Data</option>
                <option value="1">Nimi</option>
                <option value="2">auto</option>
              </select>
            <h5 className="h5">jargi</h5>
          </div>
          <div className="kutusTableInner" id="kutusTableInner" />
        </>
    );
}