import React, {useContext, useState} from "react"
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext"
import { useNavigate } from "react-router-dom";
import logo from "../images/strongway.png"

export function AuthPage() {
    const enter = useNavigate();
    const auth = useContext( AuthContext )
    const {loading, request} = useHttp()
    const [form, setForm] = useState({
      email: '', password: ''
    })
  
    const changeHandler = event =>{
        setForm({...form, [event.target.name]: event.target.value})
    }

    const loginHandler = async() =>{
        try{
            const data = await request('/api/auth/login', 'POST', {...form})
            auth.login(data.token, data.userId, data.isAdmin, data.fullName, data.town, data.carType, data.tour)
            if(auth.isAdmin === true){enter('/Admin')}
            else enter('/Home')
        } catch (e){}
    }

    return(
        <div className='AuthPageContainer'>
            <img className="logo" src={logo} alt='logo' />
            <div className="AuthPageContainer">
                <div>
                    <input placeholder='enter email' 
                    id='email' 
                    type="text" 
                    name='email'
                    value={form.email}
                    className='authPageInput'
                    onChange={changeHandler} />
                </div>
                <div>
                    <input placeholder='password' 
                    id='password' 
                    type="password" 
                    name='password' 
                    value={form.password} 
                    className='authPageInput'
                    onChange={changeHandler} />
                </div>
                <div className="registerButtonDiv">
                    <button className="registerButton" disabled={loading} onClick={loginHandler} >Log in</button>
                </div>  
            </div>
        </div>
       
    )}

