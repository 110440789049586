import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {rroutes} from './routes'
import { useAuth } from './hooks/auth.hook.js'
import {AuthContext} from './context/AuthContext'


function App() {
  const {token, login, logout, userId, isAdmin, fullName, town, carType, tour} = useAuth()
  const isAuthenticated = !!token
  const routes = rroutes(isAuthenticated, isAdmin, fullName, town, carType, tour)

  return (
    <>
    <AuthContext.Provider value={{
      token, login, logout, userId, isAuthenticated, isAdmin, fullName, town, carType, tour
    }}>
    <Router>
            {routes} 
    </Router>
    </AuthContext.Provider>
    </>
  );
}

export default App;
