import React , {useContext, useState, useEffect} from "react";
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext";
import {createTourTable} from "./functions/createTourTable";

export function ScheduleByTour(){
    const { token } = useContext(AuthContext)
    const { request } = useHttp()
    const [nr, setNr] = useState(null)
    const [hidden, setHidden] = useState(false)

    const getHandler = async() =>{
        let tour = document.getElementById('tour').value
        let month = document.getElementById('month').value
        let year = document.getElementById('year').value
        try{
          const data = await request('/api/admin/tour', 'POST', {tour, month, year} ,{
            Authorization: `Bearer ${token}`  
          })
          createTourTable(data); setHidden(true)
        } catch(e){
            alert(e.message)
        }
      }
      function sort(nr){
        let tBodies = document.querySelector('tbody')
        let tr = document.querySelectorAll('tr')
        let sortedRows = Array.from(tr)
        .slice(1)
        .sort((rowA, rowB) => rowA.cells[nr].innerHTML > rowB.cells[nr].innerHTML ? 1 : -1);
        tBodies.append(...sortedRows);
      }
      useEffect(()=>{
        let selection = document.getElementById('selection')
        if(nr != null)sort(nr)
        if(!hidden){selection.style.display = 'none'}
        if(hidden){selection.style.display = ''}
      },[nr, hidden])
      function change(){
        let sortSelection = document.getElementById('options').value
        setNr(sortSelection)
      }
    return(
        <>
        <div className="toografik">
                <div  className="inputsDiv">
                    <div className="TimeInputs">
                        <div className="inputInner">
                        <h5  className="inputName">tour</h5>
                        <input type="Number" 
                        className="inputNameDate" 
                        name='tour' 
                        id="tour" />
                        </div>
                        <div className="inputInner">
                        <h5  className="inputName">kuu</h5>
                        <input type="Number" 
                        className="inputNameDate" 
                        name='month'  
                        id="month" />
                        </div>
                        <div className="inputInner">
                        <h5  className="inputName">aasta</h5>
                        <input type="Number" 
                        className="inputNameDate" 
                        name='year' 
                        id="year" />
                        </div>
                    </div>
                    <div className="buttonDiv">
                    <button className="push_button" onClick={getHandler}>otsi</button>   
                    </div>                    
                </div>
        </div>
        <div className="selection" id="selection">
            <h5 className="h5">sorteeri</h5>
              <select onChange={change} className="kutus authPageInput" id="options"> 
                <option value="null"></option>
                <option value="0">Data</option>
                <option value="1">Nimi</option>
              </select>
            <h5 className="h5">jargi</h5>
          </div>
        <div className="TableInner" id="TableInner" />  
        </>
    );
}