export function getKuuInput(){
    let tr = this.parentNode.parentNode
    let inputValue = this.value

    for(let i = 2; i < tr.childNodes.length; i++){
        let td = tr.childNodes[i].childNodes[0]
        if(!td.classList.contains('yellow') && !td.classList.contains('red')){
        td.value = inputValue
        }
    }
}