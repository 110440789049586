import {getKuuInput} from './getKuuInput'
import {getSunday} from './getSunday'
import { CreateKuuGrafikButton } from './createKuuGrafikButton'
export function addNewTableRow(days, month, year){
    let tbody = document.getElementById('tbody')
    let row2 = document.createElement('tr');
    let heading2 = document.createElement('th')
    let input = document.createElement('input')
    let input1 = document.createElement('input')
    let piirkond = document.createElement('td')
    let tourInput = document.createElement('input')
    tourInput.className = 'tourInput'            
    tourInput.onchange = getKuuInput;
    row2.id = 'row2'
    heading2.classList.add('ferst')
    input.className = 'kuuGrafikInput'
    input1.className = 'thInput'
    input1.id = 'thInput'
    piirkond.appendChild(input)
    heading2.appendChild(input1)
    heading2.appendChild(tourInput)
    row2.appendChild(heading2)
    row2.appendChild(piirkond)    
    tbody.appendChild(row2)
    CreateKuuGrafikButton(heading2)
    for(let j = 1; j <= days; j++){
        let td = document.createElement('td')
        td.className = 'kuuGrafikTd'
        let input = document.createElement('input')
        input.className = 'kuuGrafikInput'
        td.appendChild(input)
        row2.appendChild(td)
        getSunday(year, month, j, input)
        getSunday(year, month, j, td)
    }
}