import { Route, Routes } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { AuthPage } from './pages/AuthPage'
import { HomePage } from './pages/HomePage'
import { Register } from './components/register'
import { Kataloog } from './pages/Kataloog'
import { ScheduleByTown } from './components/ScheduleByTown'
import { ScheduleByName } from './components/ScheduleByName'
import { KutuseGrafik } from './components/kutuseGrafik'
import { HomeWorkGrafik } from './components/homeWorkGrafik'
import { HomeKutuseGrafik } from './components/HomeKutuseGrafik'
import { AdminkuuGrafik } from './components/adminKuuGrafik'
import { HomeKuuGrafik } from './components/HomeKuuGrafik'
import { ChangeRegister } from './components/changeRegister'
import { WurthEst } from './components/kataloog/WurthEst'
import { WurthRu} from './components/kataloog/WurthRu'
import { OrebroEst } from './components/kataloog/OrebroEst'
import { OrebroRu } from './components/kataloog/OrebroRu'
import {ScheduleByTour} from './components/ScheduleByTour'

const PhotoPage = lazy(()=> import('./pages/PhotoPage'))
const AdminPage = lazy(()=> import('./pages/AdminPage'))

export function rroutes(isAuthenticated) {
    if (isAuthenticated) {
      return (
<Routes>
        <Route path="/Photo" element={
          <Suspense fallback='Loading...'> <PhotoPage /></Suspense>} />
        <Route path="/Home"  element={<HomePage />}>
          <Route path="/Home/HomeWorkGrafik" element={<HomeWorkGrafik />} />
          <Route path="/Home/HomeKutuseGrafik" element={<HomeKutuseGrafik />} />
          <Route path="/Home/HomeKuuGrafik" element={<HomeKuuGrafik />} />
        </Route>
        <Route path="/Admin" element={
          <Suspense fallback='Loading...'><AdminPage/></Suspense>} >
              <Route path='/Admin/changeRegister' element={<ChangeRegister />} />
              <Route path='/Admin/register' element={<Register />} />
              <Route path='/Admin/grafik/town' element={<ScheduleByTown />} />
              <Route path='/Admin/grafik/name' element={<ScheduleByName />} />
              <Route path='/Admin/grafik/tour' element={<ScheduleByTour />} />
              <Route path='/Admin/kutus' element={<KutuseGrafik />} />
              <Route path='/Admin/kuuGrafik' element={<AdminkuuGrafik />} />
        </Route>
        <Route path='/Kataloog' element={<Kataloog />}>
            <Route index element={<div>Vali mis tour sind huvitab</div>} />
              {/*Est*/}
              <Route path='/Kataloog/Wurth/est' element={<WurthEst />}/>
              <Route path='/Kataloog/Orebro/est' element={<OrebroEst />}/>
              <Route />
              {/*Rus*/}
              <Route path='/Kataloog/Wurth/rus' element={<WurthRu />}/>
              <Route path='/Kataloog/Orebro/rus' element={<OrebroRu />}/>
              <Route />
        </ Route>
        <Route path="*"  element={<HomePage />} />
</Routes>
)}

return (
    <Routes>
      <Route path="*" element={<AuthPage />}>
      </Route>
    </Routes>
  )}