import Wurth from '../../WurthPhoto/Wurth.jpg'
import LaagriWurth from '../../WurthPhoto/LaagriWurth.jpg'
import KeilaWurth from '../../WurthPhoto/KeilaWurth.jpg'
import LasnamaeWurth from '../../WurthPhoto/LasnamaeWurth.jpg'
import KadakaWurth from "../../WurthPhoto/KadakaWurth.png"
import VeerenniWurth from '../../WurthPhoto/VeerenniWurth.png'
import kopliWurth from "../../WurthPhoto/kopliWurth.jpg"

export function WurthRu() {
    return(
        <>
            <div className="wurthDiv">
                <h3>Würth tour</h3>
                <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={Wurth} />
                    <div>
                        <p className="P-marginBottom">
                            Краткое описание: загрузка происходит с 16ти до 17ти, по адресу: Parking lot, Peetri, 75312 Harju maakond. 
                            Загрузка происходит с одной из двух эстакад расположенных возле 
                            двери с вывеской "Kauba vastuvõtt", если двери закрыты - нужно поднять ворота для микроавтобусов.
                            Во время загрузки необходимо отсканировать весь товар а так же отправить логисту кол-во товара по типу:
                            "название магазина: кол-во товара" (пример:"Lasnamäe würth: 1 eur, 0,5 alust, 0.5 alust plaatit, N x plaatit")
                        </p>
                    
                        <li>1 eur = 1 полет стандартного размера</li>
                        <li>0.5 alust или просто 0.5 означает половину полета</li>
                        <li>0.5 alust plaatit означает половину полета, на котором расположены длинные железные платы</li>
                        <li>plaatit означает кол-во единиц штучного товара </li>
                    </div>
                </div>    
                <p className="P-marginBottom">После того, как пересчет товара закончен, загрузка происходит в соответствии с
                    маршрутом. План загрузки:</p>
                    <li>Lasnamäe würth (Peterburi würth)(Peterburi tee 81, 13626 Tallinn)</li> 
                    <li>Veerenni würth (Varre 1, 10138 Tallinn)</li>      
                    <li>Kopli würth (Volta würth)(Kopli 68, 10412 Tallinn)</li>  
                    <li>Kadaka würth (Kadaka tee 4, 10621 Tallinn)</li>   
                    <li>Keila würth (Paldiski mnt 35a, Keila, 76606 Harju maakond)</li>                                                                                  
                    <li>Laagri würth (Valdmäe 1a, Tänassilma, 76406 Harju maakond)</li>
                <p className="P-marginBottom">Далее отдельно по каждому магазину:</p>
                <div className="wurthDiv">
                    <h4>Laagri Würth №7624</h4>
                    <div className='wurthdiv'>
                        <img className='wurthPhoto' alt='Siia laetakse pilt' src={LaagriWurth} />
                        <p className="P-marginBottom">
                            Открываем дверь, и первым делом снимаем сигнализацию (достаточно просто ввести код сигнализации).
                            Пульт сигнализации находится сразу возле двери с правой стороны. 
                            Товар ставим напротив двери, на полу есть метка, которую нельзя переступать. Перед уходом, следует
                            убедиться, что дверь закрыта на защёлку, поставить обратно сигнализацию (вводим пароль и нажимаем
                            кнопку "arm"), закрываем дверь на ключ и едем дальше.
                        </p>   
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Keila Würth №6438</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={KeilaWurth} />
                        <p className="P-marginBottom">
                            Вход на склад со стороны склада Bauhof. Ключ от калитки для въезда на территорию находится в связке
                            ключей (калитку закрывают в 18.50). Система снятия сигнализации та же, для снятия нужно просто
                            ввести код, а что бы поставить помещение на сигнализацию, ввести код и нажать кнопку "arm".
                            В случае, если возле входа стоят палеты для dpd на главный склад Würth, их следует отсканировать 
                            через "Tühi stopp" и отвезти на склад dpd.
                        </p>
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Kadaka Würth №3699</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={KadakaWurth} />
                        <p className="P-marginBottom">
                            Вход на склад со стороны улицы artelli. Для снятия сигнализации необходимо ввести пароль, нажать "Ок"
                            и затем "off", что бы поставить на сигнализацию, вводим пароль, "Ок", "On".
                        </p>
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Volta Würth №7461</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={kopliWurth} />
                        <p className="P-marginBottom">
                            Для снятия сигнализации необходимо ввести пароль и нажать кнопку "sisse".
                            Что бы поставить помещение на сигнализацию нужно ввести код и нажать кнопу "välja".
                        </p>
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Veerenni Würth №6686</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={VeerenniWurth} />
                        <p className="P-marginBottom">
                            Здесь сигнализация снимается как и на кадака. Вводим пароль, "ок", "off". Перед уходом вводим пароль,
                            "Ок", "On".
                        </p>        
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Peterburi Würth №6584</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={LasnamaeWurth} />
                        <p className="P-marginBottom">
                            Заезд на склад находится с противоположной стороны здания. Для снятия сигнализации достаточно просто
                            ввести пароль. Что бы поставить помещение на сигнализацию вводим пароль, и нажимаем кнопку "arm".
                            Отдельного склада в магазине нет, место выгрузки обозначено лентой, пересекать которую нельзя. 
                        </p>
                    </div>
                </div>
            </div>
        </>
    )}