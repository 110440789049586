export function kutuseGrafikTable(data){
    let kutusTableInner = document.getElementById('kutusTableInner');
    kutusTableInner.innerHTML=''
    let table = document.createElement('table');
    let thead = document.createElement('thead');
    let tbody = document.createElement('tbody');
    kutusTableInner.appendChild(table);
    table.appendChild(thead);
    table.appendChild(tbody);

//первая строка таблицы
    let row1 = document.createElement('tr');
    row1.className = "row1";
    row1.id = "row1";
    thead.appendChild(row1)
    let heading = document.createElement('th');
    heading.innerHTML = 'Data';
    heading.className = 'kutuseTh'
    row1.appendChild(heading);
    let heading1 = document.createElement('th');
    heading1.innerHTML = 'Nimi ja perekonnanimi';
    heading1.className = 'kutuseTh'
    row1.appendChild(heading1);
    let heading5 = document.createElement('th');
    heading5.innerHTML = 'auto'
    heading5.className = 'kutuseTh'
    row1.appendChild(heading5);
    let heading2 = document.createElement('th');
    heading2.innerHTML = 'Diesel (L)';
    heading2.className = 'kutuseTh'
    row1.appendChild(heading2);
    let heading3 = document.createElement('th');
    heading3.innerHTML = 'adBlue (L)';
    heading3.className = 'kutuseTh'
    row1.appendChild(heading3);
    let heading4 = document.createElement('th');
    heading4.innerHTML = 'odometrinäit';
    heading4.className = 'kutuseTh'
    row1.appendChild(heading4);

    // заполнение таблицы
    for(let i = 0; i < data.length; i++){
    // Дата        
        let tr = document.createElement('tr')
        let month = data[i].month
        let day = data[i].day
        if(day < 10){day = `0${day}`}
        if(month < 10){month = `0${month}`}
        let cell = document.createElement('td')
        cell.innerHTML = `${day}.${month}`
        tbody.appendChild(tr)
        tr.appendChild(cell)
        // имя и фамилия
        let name = data[i].fullName
        let cellName = document.createElement('td')
        cellName.innerHTML = name
        tr.appendChild(cellName)
        //номер машины
        let car = data[i].carNumber
        let cellCar = document.createElement('td')
        cellCar.innerHTML = car
        tr.appendChild(cellCar)
        //Дизель
        let diesel = data[i].diesel
        let cellDiesel = document.createElement('td')
        cellDiesel.innerHTML = diesel
        tr.appendChild(cellDiesel)
        //adBlue
        let adBlue = data[i].adBlue
        let cellAdBlue = document.createElement('td')
        cellAdBlue.innerHTML = adBlue
        tr.appendChild(cellAdBlue)
        //одометр
        let km = data[i].odometer
        let cellkm = document.createElement('td')
        cellkm.innerHTML = km
        cellkm.className = 'usualyTd'
        tr.appendChild(cellkm)
    }
}