import React , {useContext, useEffect, useState} from "react";
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext";
import { createTable } from "./functions/createTable"
import { NewTable } from "./functions/newTable"
import { tableName } from "./functions/tableName";
import { getLastDayOfMonth } from "./functions/getLastDayOfMonth";

export function ScheduleByName(){
    const { token } = useContext(AuthContext)
    const { request } = useHttp()
    const [state, setState] = useState(0)

async function getHandler(){
try{
  const data = await request('/api/admin/user', 'POST', null,{
    Authorization: `Bearer ${token}`
  })
      let name1 = document.querySelector('#Name')    
      for(let i = 0; i < data.ownersArray.length; i++){
      let option1 = document.createElement('option');
      name1.appendChild(option1)
      let data1 = data.ownersArray[i];
      option1.innerHTML = data1[0];
      option1.value = `${data1[1]}`;
}
} catch(e){
  alert(e.message)
}}
useEffect(()=>{
  getHandler()
}, [])
 
async function getName(){
  let userId = document.getElementById('Name').value
  let year = document.getElementById('year').value
  let month = document.getElementById('month').value
  try{
    const data = await request('/api/admin/name', 'POST', {userId, year, month},{
      Authorization: `Bearer ${token}`
    })
    
    let pakk = data.link.find(item => item.pakk)
    let days = getLastDayOfMonth(year, month)
    let userName = data.link[0].ownerName
    createTable(state, days, year, month -1, pakk).then(tableName(userName, state)).then(NewTable(data.link, year, state))
    setState(state + 1)
    console.log(data)
  } catch(e){
      alert(e.message)
  }
}
 
    return(
        <>
        <div className="registerInputs inputsDiv">
              <select id="Name" className='authPageInput'/>           
                <input placeholder="aasta" 
                className='authPageInput'
                type= "number"
                id="year" />
                <input placeholder="kuu"
                className='authPageInput'
                type= "number"
                id="month" />
        <div className="buttonDiv">
              <button onClick={getName} className="push_button"> Näita </button>
        </div>      
          </div>
          <div className="TableInner" id="TableInner" />
        </>
    );
}