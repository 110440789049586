import React, {useContext, useEffect, useState} from "react"
import { AuthContext } from "../context/AuthContext";
import { useHttp } from "../hooks/http.hook"

export function ChangeRegister(){
    const { token } = useContext(AuthContext)
    const { request } = useHttp()
    const [form, setForm] = useState()
      const changeHandler = event =>{
        let name = [event.target.name]
        let value = event.target.value
        if(value!==''){
          setForm({...form, [name]: value})
        }
        if(value===''){ delete form[name] }
    }
      async function getHandler(){
        try{
          const data = await request('/api/admin/user', 'POST', null,{
            Authorization: `Bearer ${token}`
          })
              let name1 = document.querySelector('#Name')    
              for(let i = 0; i < data.ownersArray.length; i++){
              let option1 = document.createElement('option');
              name1.appendChild(option1)
              let data1 = data.ownersArray[i];
              option1.innerHTML = data1[0];
              option1.value = `${data1[1]}`;
        }
        } catch(e){
          alert(e.message)
        }}
        useEffect(()=>{
            getHandler()
        }, [])
    async function changeUserData(){
      try{
        const data = await request('/api/admin/userChange', 'POST', {...form}, {
          Authorization: `Bearer ${token}`
        })
        if(data) alert('valmis')
      } catch(e){
        alert(e.message)
      }
    }
    function deleteUser(){
      let userId = document.getElementById('Name').value
      let selected = document.getElementById('Name')
      let userName = selected.options[selected.selectedIndex].text
      let control = window.confirm(`Kas olete kindel, et soovite emaldada kasutaja ${userName}?`)
      async function deletAccepted(){
      try{      
        const data = await request('/api/admin/deleteUser', 'POST', {userId}, {
          Authorization: `Bearer ${token}`
        })
        if(data) alert(data.message)
      } catch(e){
        alert(e.message)
      }
    }
      if(control){deletAccepted()}      
    }
return(
    <div className="registerInputs inputsDiv">
        <select id="Name"
               className='authPageInput' 
               name="userId" 
               onChange={changeHandler}> 
               <option></option> 
               </select>
        <input placeholder='email'
               name="email"
               className='authPageInput'
               onChange={changeHandler}/>
        <input placeholder='password'
               name="password"
               type="password"
               className='authPageInput'
               onChange={changeHandler}
               autoComplete="new-password" />
        <select name="town" className='authPageInput' onChange={changeHandler}>
                <option></option>
                <option value="Tallinn">Tallinn</option>
                <option value="Rakvere">Rakvere</option>
                <option value="Jõhvi">Jõhvi</option>
                <option value="Tartu">Tartu</option>
        </select> 
        <input placeholder='nimi'
               name="fullName"
               className='authPageInput'
               onChange={changeHandler} />
        <input placeholder='tour'
               name="tour"
               className='authPageInput'
               onChange={changeHandler} />
        <input placeholder='piirkond'
               name="piirkond"
               className='authPageInput'
               onChange={changeHandler} />
        <select placeholder='auto tüüp' name="carType" className='authPageInput' onChange={changeHandler}>
                <option></option>
                <option value="Veok">Veok</option>
                <option value="Kaubik">Kaubik</option>
                <option value="Rekka">Rekka</option>
            </select> 
        <div className="buttonDiv">
        <button className="push_button" onClick={changeUserData}>salvesta</button> 
        <button className="submitButton push_button" onClick={deleteUser} >eemalda</button>            
        </div>
    </div>
)}