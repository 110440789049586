export function NewTable(data, year, n){
    const allMonths = ["Jaanuar","Veebruar","Märts","Aprill","Mai","Juuni","Juuli","August","September","Oktoober","November",
    "Detsember"]
          data.forEach((key) => {
          let selectedDay = key.day
          let km = key.finishKm - key.startKm
          let clients = key.clients
          let car = key.carNumber
          let pakk = key.pakk
          let tour = key.tour
          let hours = key.finish.substring(0,2) - key.start.substring(0,2)
          if(hours < 0){hours += 24}
          if(hours<=9){hours = `0${hours}`}
          let minutes = key.finish.substring(3) -  key.start.substring(3)
          if(minutes<0){
            hours -= 1;
            minutes +=60;
          }
          if(minutes<=9){minutes = `0${minutes}`}

          if(hours<0){ hours += 24 }
          document.querySelector(`#tournr${n}`).cells[selectedDay].innerHTML = `${tour}`
          document.querySelector(`#carNr${n}`).cells[selectedDay].innerHTML = `${car}`
          document.querySelector(`#time${n}`).cells[selectedDay].innerHTML = `${hours}:${minutes}`
          document.querySelector(`#km${n}`).cells[selectedDay].innerHTML = `${km}`
          document.querySelector(`#clients${n}`).cells[selectedDay].innerHTML = `${clients}`
          if(pakk !== null && pakk !== undefined){document.querySelector(`#pakk${n}`).cells[selectedDay].innerHTML = `${pakk}`}
          document.querySelector(`#row${n}`).lastChild.innerHTML = "Kokku"
          });

          let array = [];
          data.forEach(item => {if(item.pakk !== null && item.pakk !== undefined){
            array.push(item.pakk)
          }})
          let pakkKokku;
          if(array.length >= 1){
            pakkKokku = array.reduce(function(sum, current){
              let value =+ sum + current
            return value
          }, [0])            
          }
          let KmKokku = data.reduce(function(sum, current){
            let km = current.finishKm - current.startKm
            let value =+ sum + km
            return value
          }, [0])
          let clientsKokku = data.reduce(function(sum, current){
            let clients = current.clients
            let value =+ sum + clients
            return value
          }, [0])          
          let kokkuHours = data.reduce(function(sum, current){
            let hours = current.finish.substring(0,2) - current.start.substring(0,2)
            if(hours < 0){ hours += 24}
            let value =+ sum + hours
            return value
          },[0])
          let kokkuMin = data.reduce(function(sum, current){
            let minutes = current.finish.substring(3) -  current.start.substring(3)
            if(minutes < 0){
              minutes += 60
              kokkuHours -= 1
            }
            let value =+ sum + minutes
            return value
          }, [0])
          if(kokkuMin > 60){
            let hrs = Math.floor(kokkuMin/60);
            kokkuHours = kokkuHours + hrs
            kokkuMin = kokkuMin - (hrs * 60)
          }
          document.querySelector(`#time${n}`).lastChild.innerHTML = `${kokkuHours}:${kokkuMin}`
          document.querySelector(`#km${n}`).lastChild.innerHTML = KmKokku
          document.querySelector(`#clients${n}`).lastChild.innerHTML = clientsKokku
          if(pakkKokku >= 1){
          document.querySelector(`#pakk${n}`).lastChild.innerHTML = pakkKokku  
          }
          let thisMonth = allMonths[data.shift().month - 1]
          document.getElementById(`mscgod${n}`).innerHTML = `${thisMonth}  ${year}`
    }