import React, {useContext, useState} from "react"
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext"
import { kutuseGrafikTable } from './functions/kutuseGrafikTable'
import logo from "../images/strongway.png"

export function HomeKutuseGrafik() {
    const { token } = useContext(AuthContext)
    const { request } = useHttp()
      const [form, setForm] = useState({
        day: `${new Date().getDate()}`, month: `${new Date().getMonth() + 1}`, year: `${new Date().getFullYear()}`,
        carNumber: '',  adBlue: '', diesel: '', odometer: '', tour: ''})
      const changeHandler = event =>{
          setForm({...form, [event.target.name]: event.target.value})
        }
    async function addHandler(){
      try{
        const data = await request('/api/kutus/add', 'POST', {...form},{
          Authorization: `Bearer ${token}`
        })
        if(data){alert('valmis')}
      } catch(e){
        alert(e.message)
      }}
      async function getHandler(){
        let table = document.getElementsByClassName('row1')
        let date = new Date();
        let year = date.getFullYear();
        let  month = date.getMonth() + 1;
        try{
          const data = await request('/api/kutus/get', 'POST', {year, month},{
            Authorization: `Bearer ${token}`
          })
          if(table.length === 0){kutuseGrafikTable(data)}
        } catch(e){
          alert(e.message)
        }}
    return(
      <>
          <div className="homeContainer">
                    <div  className="inputsDiv">
                        <div className="TimeInputs">
                        <div className="inputInner">
                        <h5  className="inputName">päev</h5>
                        <input type="Number" 
                        max="31"
                        className="inputNameDate" 
                        name='day' 
                        id="day"
                        value={form.day} 
                        onChange={changeHandler} />
                        </div>
                        <div className="inputInner">
                        <h5  className="inputName">kuu</h5>
                        <input type="Number" 
                        max="12"
                        className="inputNameDate" 
                        name='month'  
                        id="month"
                        value={form.month}
                        onChange={changeHandler} />
                        </div>
                        <div className="inputInner">
                        <h5  className="inputName">aasta</h5>
                        <input type="Number" 
                        className="inputNameDate" 
                        name='year' 
                        id="year"
                        value={form.year}
                        onChange={changeHandler} />
                        </div>
                        </div>
                        <div className="TimeInputs">
                        <div className="aegInput">
                        <h5  className="inputName">Auto</h5>
                        <input type="string" 
                        className="inputsaeg" 
                        name='carNumber'
                        id="CarNumber"
                        value={form.carNumber} 
                        onChange={changeHandler} />
                        </div>
                        <div className="aegInput">
                        <h5 className="inputName">tour</h5>
                        <input
                        className="inputsaeg" 
                        name="tour"
                        id="tour"
                        value={form.tour}
                        onChange={changeHandler} />
                        </div>
                        </div>
                        <div className="TimeInputs">
                        <div className="inputInner">
                        <h5  className="inputName">diesel</h5>
                        <input type="number" 
                        className="inputsaeg"   
                        name='diesel'
                        id="diesel"
                        value={form.diesel}
                        onChange={changeHandler} />
                        </div>
                        <div className="inputInner">
                        <h5  className="inputName">adBlue</h5>
                        <input type="number" 
                        className="inputsaeg"   
                        name='adBlue'
                        id="adBlue"
                        value={form.adBlue}
                        onChange={changeHandler} />
                        </div>
                        </div>
                        <h5  className="inputName">läbisõit</h5>
                        <input type="number"   
                        name='odometer'
                        id="odometer"
                        value={form.odometer}
                        onChange={changeHandler} />
                        <div className='buttonDiv'>
                        <button className="push_button" onClick={addHandler} >saada</button>                                  
                        <button className="submitButton push_button" onClick={getHandler}>grafik</button>
                </div> 
                </div>
                <img className="logoHome" src={logo} alt='logo' />
                </div>
                <div className="kutusTableInner" id="kutusTableInner" />
      </>
    )}