import React , {useContext} from "react";
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext";
import { createTable } from "./functions/createTable"
import { NewTable } from "./functions/newTable"
import { tableName } from "./functions/tableName";
import { getLastDayOfMonth } from "./functions/getLastDayOfMonth";

export function ScheduleByTown(){
    const { token } = useContext(AuthContext)
    const { request } = useHttp()

    const getHandler = async() =>{
        let town = document.getElementById('town').value
        let year = document.getElementById('year').value
        let month = document.getElementById('month').value
        try{
          const data = await request('/api/admin/town', 'POST', {town, year, month},{
            Authorization: `Bearer ${token}`
          })
          let days = getLastDayOfMonth(year, month)
          let users = new Set();
            for (let i = 0; i < data.link.length; i++){
            users.add(data.link[i].owner)}
          let usersArray = Array.from(users)
          let linksArray = []
          document.getElementById('TableInner').innerHTML = ''
        async function createTownTable(usersArray, data){
            for(let i = 0; i < usersArray.length; i++){
                let userID = usersArray[i]
                let array = []
                for(let J = 0; J < data.link.length; J++){
                    if(data.link[J].owner === userID){
                        array.push(data.link[J])
                    }
                }
                linksArray.push(array)
                let pakk = array.find(item => item.pakk)
                createTable(i, days, year, month -1, pakk).then(tableName(array[0].ownerName, i)).then(NewTable(array, year, i)) 
            }
        } createTownTable(usersArray, data)

        } catch(e){
            alert(e.message)
        }
      }
    return(
        <div className="toografik">
            <div className="registerInputs inputsDiv">
            <select id="town" className='authPageInput'>
                <option value="Tallinn">Tallinn</option>
                <option value="Rakvere">Rakvere</option>
                <option value="Jõhvi">Jõhvi</option>
                <option value="Tartu">Tartu</option>
            </select> 
            <input placeholder="aasta" 
                className='authPageInput'
                type="String" 
                id="year" />
            <input placeholder="kuu" 
                className='authPageInput'
                type="String" 
                id="month" />
            <div className="buttonDiv">
                <button onClick={getHandler} className="push_button">Näita</button>
            </div>
            </div>
            <div className="TableInner" id="TableInner" />  
        </div>
    );
}