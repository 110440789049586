import {getSunday} from './getSunday'
import { exportTableToExcel } from './exportTableToExcel'
export async function createTable(n, days, year, month, pakk){
    let inner = document.getElementById('TableInner')
    let tableInner = document.createElement('div')
    tableInner.id = `table${n}`
    tableInner.className = 'CreateTable'
    inner.appendChild(tableInner)    
    let tableDiv = document.querySelector(`#table${n}`);
    let table = document.createElement('table');
    let thead = document.createElement('thead');
    let tbody = document.createElement('tbody');
    let exportButton = document.createElement('button')
    table.id = `${n}`

    table.appendChild(thead);
    table.appendChild(tbody);
    tableDiv.appendChild(table); 
//первая строка таблицы
    let row1 = document.createElement('tr');
    row1.className = "row1";
    row1.id = `row${n}`
    let heading1 = document.createElement('th');
    heading1.innerHTML = '';
    heading1.className = 'esimene';
    heading1.id = `mscgod${n}`;
    row1.appendChild(heading1);
    thead.appendChild(row1);
    for(let i = 1; i <=days + 1; i++){
        let heading = document.createElement('th');
        heading.innerHTML = i;
        if(i<=9){heading.innerHTML =`0${i}`}
        if(i===32){heading.innerHTML = ''}
        row1.appendChild(heading);
        thead.appendChild(row1);
        if(i <= days){getSunday(year, month, i, heading)}
    };
//вторая строка таблицы
    let row2 = document.createElement('tr');
    row2.className = "km";
    row2.id = `km${n}`;
    let heading2 = document.createElement('th');
    heading2.innerHTML = "km";
    heading2.className = "esimene";
    heading2.id = `esimene${n}`;
    row2.appendChild(heading2);
    tbody.appendChild(row2);
    for(let i = 1; i <=days + 1; i++){
        let tableRow2 = document.createElement('td');
        tableRow2.innerHTML = '';
        tableRow2.className = 'tableTd'
        row2.appendChild(tableRow2);
        tbody.appendChild(row2);
        if(i <= days){getSunday(year, month, i, tableRow2)}
    };
//третья строка таблицы
    let row3 = document.createElement('tr');
    row3.className = "clients";
    row3.id = `clients${n}`;
    let heading3 = document.createElement('th');
    heading3.innerHTML = "stopp";
    heading3.className = "esimene";
    heading3.id = `esimene${n}`;
    row3.appendChild(heading3);
    tbody.appendChild(row3);
    for(let i = 1; i <=days + 1; i++){
        let tableRow3 = document.createElement('td');
        tableRow3.innerHTML = '';
        tableRow3.className = 'tableTd'
        row3.appendChild(tableRow3);
        tbody.appendChild(row3);
        if(i <= days){getSunday(year, month, i, tableRow3)}
    };
//четвёртая строка таблицы
    let row4 = document.createElement('tr');
    row4.className = "time";
    row4.id = `time${n}`;
    let heading4 = document.createElement('th');
    heading4.innerHTML = "aeg";
    heading4.className = "esimene";
    heading4.id = `esimene${n}`;
    row4.appendChild(heading4);
    tbody.appendChild(row4);
    for(let i = 1; i <=days + 1; i++){
        let tableRow4 = document.createElement('td');
        tableRow4.innerHTML = '';
        tableRow4.className = 'tableTd'
        row4.appendChild(tableRow4);
        tbody.appendChild(row4);
        if(i <= days){getSunday(year, month, i, tableRow4)}
    };
    //пятая строка таблицы
    let row5 = document.createElement('tr');
    row5.className = "carNr";
    row5.id = `carNr${n}`;
    let heading5 = document.createElement('th');
    heading5.innerHTML = "auto";
    heading5.className = "esimene"
    heading5.id = `esimene${n}`
    row5.appendChild(heading5);
    tbody.appendChild(row5);
    for(let i = 1; i <=days + 1; i++){
        let tableRow5 = document.createElement('td');
        tableRow5.innerHTML = '';
        tableRow5.className = 'tableTd'
        row5.appendChild(tableRow5);
        tbody.appendChild(row5);
        if(i <= days){getSunday(year, month, i, tableRow5)}
    };
    //шестая строка таблицы
    let row6 = document.createElement('tr');
    row6.className = "tournr";
    row6.id = `tournr${n}`;
    let heading6 = document.createElement('th');
    heading6.innerHTML = "tuur";    ////////
    heading6.className = "esimene"
    heading6.id = `esimene${n}`
    row6.appendChild(heading6);
    tbody.appendChild(row6);
    for(let i = 1; i <=days + 1; i++){
        let tableRow6 = document.createElement('td');
        tableRow6.innerHTML = '';
        tableRow6.className = 'tableTd'
        row6.appendChild(tableRow6);
        tbody.appendChild(row6);
        if(i <= days){getSunday(year, month, i, tableRow6)}
    };
    //строка номер 7, при условии наличия данных о pakk
    if(pakk !== undefined && pakk !==null && pakk !== 0){
    let row7 = document.createElement('tr');
    row7.className = "pakk";
    row7.id = `pakk${n}`;
    let heading7 = document.createElement('th');
    heading7.innerHTML = "pakk";
    heading7.className = "esimene"
    heading7.id = `esimene${n}`
    row7.appendChild(heading7);
    tbody.appendChild(row7);
    for(let i = 1; i <=days + 1; i++){
        let tableRow7 = document.createElement('td');
        tableRow7.innerHTML = '';
        tableRow7.className = 'tableTd'
        row7.appendChild(tableRow7);
        tbody.appendChild(row7);
        if(i <= days){getSunday(year, month, i, tableRow7)}
    };
    }

    exportButton.innerHTML = 'download excel'
    exportButton.onclick = () => exportTableToExcel(`${n}`)
    tableDiv.after(exportButton);

}