export function createTourTable(data){
    let tableInner = document.getElementById('TableInner');
    tableInner.innerHTML = '';
    let table = document.createElement('table');
    let thead = document.createElement('thead');
    let tbody = document.createElement('tbody');
    tableInner.appendChild(table);
    table.appendChild(thead);
    table.appendChild(tbody);
    //первая строка таблицы
    let row1 = document.createElement('tr');
    row1.className = "row1";
    row1.id = "row1";
    thead.appendChild(row1)
    let heading = document.createElement('th');
    heading.innerHTML = 'Data';
    heading.className = 'kutuseTh'
    row1.appendChild(heading);
    let heading1 = document.createElement('th');
    heading1.innerHTML = 'Nimi ja perekonnanimi';
    heading1.className = 'kutuseTh'
    row1.appendChild(heading1);
    let heading2 = document.createElement('th');
    heading2.innerHTML = 'auto';
    heading2.className = 'kutuseTh'
    row1.appendChild(heading2);
    let heading3 = document.createElement('th');
    heading3.innerHTML = 'aeg';
    heading3.className = 'kutuseTh'
    row1.appendChild(heading3);
    let heading4 = document.createElement('th');
    heading4.innerHTML = 'km';
    heading4.className = 'kutuseTh'
    row1.appendChild(heading4);
    let heading5 = document.createElement('th');
    heading5.innerHTML = 'clients';
    heading5.className = 'kutuseTh'
    row1.appendChild(heading5);
    let heading6 = document.createElement('th');
    heading6.innerHTML = 'pakk';
    heading6.className = 'kutuseTh'
    row1.appendChild(heading6);
    //заполнение таблицы
    for(let i = 0; i < data.link.length; i++){
        //Дата
        let tr = document.createElement('tr')
        let month = data.link[i].month
        let day = data.link[i].day
        if(day < 10){day = `0${day}`}
        if(month < 10){month = `0${month}`}
        let cell = document.createElement('td')
        cell.innerHTML = `${day}.${month}`
        tbody.appendChild(tr)
        tr.appendChild(cell)
        // имя и фамилия
        let name = data.link[i].ownerName
        let cellName = document.createElement('td')
        cellName.innerHTML = name
        tr.appendChild(cellName)
        //номер машины
        let car = data.link[i].carNumber
        let cellCar = document.createElement('td')
        cellCar.innerHTML = car
        tr.appendChild(cellCar)
        //время
        let hours = data.link[i].finish.substring(0,2) - data.link[i].start.substring(0,2)
        if(hours < 0){hours += 24}
        if(hours<=9){hours = `0${hours}`}
        let minutes = data.link[i].finish.substring(3) -  data.link[i].start.substring(3)
        if(minutes<0){
          hours -= 1;
          minutes +=60;
        }
        if(minutes<=9){minutes = `0${minutes}`}
        if(hours<0){ hours += 24 }
        let cellTime = document.createElement('td')
        cellTime.innerHTML = `${hours}:${minutes}`
        tr.appendChild(cellTime)
        //км
        let km = data.link[i].finishKm - data.link[i].startKm
        let cellKm = document.createElement('td')
        cellKm.innerHTML = km
        tr.appendChild(cellKm)
        //клиенты
        let clients = data.link[i].clients
        let cellClients = document.createElement('td')
        cellClients.innerHTML = clients
        tr.appendChild(cellClients)
        //pakk
        let pakk = data.link[i].pakk
        if(pakk === null){pakk="-"}
        let cellPakk = document.createElement('td')
        cellPakk.innerHTML = pakk
        cellPakk.className = 'usualyTd'
        tr.appendChild(cellPakk)
    }
}