export function saturdayComponents(props){
    const inner = document.getElementById('saturdayDiv')
    const button = document.createElement('button')
    const linksArray = []
    let th
    function deleteButton(){
        document.getElementById('saturdayDiv').innerHTML = ''
    }

    button.innerText = 'X'
    button.onclick = deleteButton
    button.className = 'saturdayComponent__button'
    //определяем имена пользователей, кто работал по субботам
    for(let i = 0; i < props.length; i++){
        th = document.getElementById(`${props[i].ownerName}`)
        let usersName = th.children[2]
        if(!th.classList.contains('saturdayDecoration')){usersName.classList.add('saturdayDecoration')}
        if(!linksArray.includes(props[i].ownerName)){linksArray.push(props[i].ownerName)}
    }
    for(let i = 0; i < linksArray.length; i++){
        th = document.getElementById(`${linksArray[i]}`)
        let links = props.filter(item => item.ownerName == linksArray[i])
        th.onclick = (event) => {
            if(event.target.className !== 'tourInput' && event.target.className !== 'ferstButton' &&
            event.target.className !== 'ringsButton'){
            document.getElementById('saturdayDiv').innerHTML = ''
            links.forEach(function(item){
                inner.insertAdjacentHTML(`beforeend`,`
                    <ul class="saturdayComponent__ul" >
                    <li class="saturdayComponent__li" >Data: ${item.day}.${item.month}.${item.year}</li>
                    <li class="saturdayComponent__li" >Nimi: ${item.ownerName}</li>
                    <li class="saturdayComponent__li" >Tuur: ${item.tour}</li>
                    <li class="saturdayComponent__li" >Auto: ${item.carNumber}</li>
                    <li class="saturdayComponent__li" >Stopid: ${item.clients}</li>
                    </ul>`
                )
            })
            inner.prepend(button)}
        }
    }
}