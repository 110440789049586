import {useState, useCallback, useEffect} from "react"

const storageName = 'userData'

export const useAuth = () =>{
  
    const [token, setToken] = useState(null)
    const [ready, setReady] = useState(false)
    const [userId, setUserId] = useState (null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [fullName, setFullName] = useState(null)
    const [town, setTown] = useState(null)
    const [carType, setCarType] = useState(null)
    const [tour, setTour] = useState(null)
    
    const login = useCallback((jwtToken, id, admin, fullName, town, carType, tour) =>{
        setToken(jwtToken)
        setUserId(id)
        setIsAdmin(admin)
        setFullName(fullName)
        setTown(town)
        setCarType(carType)
        setTour(tour)
        localStorage.setItem(storageName, JSON.stringify({ userId: id, token: jwtToken, isAdmin: admin, town, fullName, carType, tour }))
 }, [])
 
 const logout = useCallback(() => {
  setToken(null)
  setUserId(null)
  setIsAdmin(null)
  setFullName(null)
  setTown(null)
  setCarType(null)
  setTour(null)
  localStorage.removeItem(storageName)
}, [])

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName))
    if (data && data.token) {
      login(data.token, data.userId, data.isAdmin, data.fullName, data.town, data.carType, data.tour)
    }     setReady(true)
  }, [login])
  return { login, logout, token, userId, ready, isAdmin, fullName, town, carType, tour }
};