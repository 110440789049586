import Wurth from '../../WurthPhoto/Wurth.jpg'
import LaagriWurth from '../../WurthPhoto/LaagriWurth.jpg'
import KeilaWurth from '../../WurthPhoto/KeilaWurth.jpg'
import LasnamaeWurth from '../../WurthPhoto/LasnamaeWurth.jpg'
import KadakaWurth from "../../WurthPhoto/KadakaWurth.png"
import VeerenniWurth from "../../WurthPhoto/VeerenniWurth.png"
import kopliWurth from "../../WurthPhoto/kopliWurth.jpg"

export function WurthEst() {
    return(
        <>
            <div className="wurthDiv">
                <h3>Würth tour</h3>
                <div className='wurthdiv'>
                <img className='wurthPhoto' alt='Siia laetakse pilt' src={Wurth} />
                    <div>   
                        <p className="P-marginBottom">Peale laadimine toimub alates kellast 16 kuni 17, adress: Parking lot,
                            Peetri, 75312 Harju maakond. Pealelaadimine toimub 1 või 2 estakadilt, mis asub "kauba vastuvõtt"
                            sildi juures. Kui uks on kinni siis vaja kaubikute uks ülesse tõsta ja nii saate sisse. 
                            Pealelaadimise ajal vaja scaneerida kõik kaup mis võtakse peale, ja saada logistikule kaupade kogus,
                            mis kauplusse vaja viia. Näidis:"Lasnamäe würth: 1 eur, 0.5 alust, 0.5 alust plaatit, plaatit"
                        </p>
                        <li>1 eur = 1 standaartne alus</li>
                        <li>0.5 alust tähendeb pool alust kaupa</li>
                        <li>0.5 alust plaatit tähendeb pool alust, mille peal on pikkad raud plaatit</li>
                        <li>plaatit tähendab üksikute kaupa kogus</li>
                    </div>         
                </div>
                <p className="P-marginBottom">Kui info on saadetud ja kaup skaneeritud hakkame pealelaadimine, edasi on plaan,
                 kuidas toimub pealelaadimine.</p>
                    <li>Lasnamäe würth (Peterburi würth)(Peterburi tee 81, 13626 Tallinn)</li> 
                    <li>Veerenni würth (Varre 1, 10138 Tallinn)</li>
                    <li>Kopli würth (Volta würth)(Kopli 68, 10412 Tallinn)</li>  
                    <li>Kadaka würth (Kadaka tee 4, 10621 Tallinn)</li>   
                    <li>Keila würth (Paldiski mnt 35a, Keila, 76606 Harju maakond)</li> 
                    <li>Laagri würth (Valdmäe 1a, Tänassilma, 76406 Harju maakond)</li>
                <p className="P-marginBottom">Edasi on mahalaadimise info:</p>
                <div className="wurthDiv">
                    <h4>Laagri Würth №7624</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={LaagriWurth} />
                        <p className="P-marginBottom">
                            Teeme uks lahti ja kõigepealt võtame signalisatsioon maha (see jaoks on vaja parool sisestada).
                            Paneme kaupa ukse vastu. Põrandal on joon mida ei tohi ületada. Enne väljaminek vaja kontrollida,
                            et uks on kunni ja siis panna signalisatsioon (sisesta parool ja vajuta "arm" nuppu).
                            Paneme uks lukku ja sõidame edasi.
                        </p>  
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Keila Würth №6438</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={KeilaWurth} />
                        <p className="P-marginBottom">
                            Ladu sissepääs asub Bauhofi ladu vastu. Territooriumi värava lukust võtt asub koos lao võtmega
                            (Territooriumi väravad panevad kinni kell 18.50). Signalisatsiioni süsteem on sama kui Laagris.
                            Kui ukse juures on kaup dpd sildiga, mis on Würth keskladu jaoks, siis seda vaja skaneerida "Tühi stop" 
                            kaudu ja kaasa võtta. Pärast viia dpd lattu.
                        </p>
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Kadaka Würth №3699</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={KadakaWurth} />
                        <p className="P-marginBottom">
                            Ladu sissepääs asub artelli tänava poolt. et võtta signalisatsioon maha vaja sisestada parool, siis
                            vajutada "Ok" nuppu ja siis "Off". Kui kaup on maas ja uks on kinni paneme signalisatsioon tagasi.
                            Sisesta parool, siis vajuta "Ok" ja "Off".
                        </p>
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Volta Würth №7461</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={kopliWurth} />
                        <p className="P-marginBottom">
                            Signalisatsiioni väljalülitamiseks sisestage parool ja vajutage "sisse".
                            Kui kaup on maas pange signalisatsioon tagasi, sisestagi parool ja vajutage "välja".
                        </p>
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Veerenni Würth №6686</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={VeerenniWurth} />
                        <p className="P-marginBottom">
                            Siin on sama süsteem kui Kadakas. Parool, "Ok", "Off".
                            Kaup maha, kontrooli et uks on kinni, siis signalisatsioon tagasi. Parool, "Ok", "On".
                        </p>
                    </div>
                </div>
                <div className="wurthDiv">
                    <h4>Peterburi Würth №6584</h4>
                    <div className='wurthdiv'>
                    <img className='wurthPhoto' alt='Siia laetakse pilt' src={LasnamaeWurth} />
                        <p className="P-marginBottom">
                            Ladu juudesõit asub teiselt poolt maja. Signalisatsioon maha: sisesta parool. Signalisatsioon tagasi:
                            sisesta parool ja vajuta nupp "arm". Kaupluses ei ole eraldi ladu, seal on root mida ei tohi ületada.
                            Kaupa vaja jata selle ruuti piirkonnas.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )}