import React, {useState} from "react"
import { useHttp } from "../hooks/http.hook"

export function Register(){
    const { request } = useHttp()
    const [form, setForm] = useState({
        email: '', password: '', fullName: '', town: '', tour: '', carType: '', piirkond: ''
      })
    const changeHandler = event =>{
        setForm({...form, [event.target.name]: event.target.value})
    }
    const registerHandler = async() =>{
        try{
            const data = await request('/api/auth/register', 'POST', {...form})
            if(data){alert('valmis')}
        } catch (e){}
    }
return(
    <div className="registerInputs inputsDiv">
        <input placeholder='email'
               name="email"
               value={form.email}
               className='authPageInput'
               onChange={changeHandler}/>
        <input placeholder='password'
               name="password"
               type="password"  
               value={form.password} 
               className='authPageInput'
               onChange={changeHandler}
               autoComplete="new-password" />
        <input placeholder='linn'
               name="town"
               value={form.town}
               className='authPageInput'
               onChange={changeHandler} />
        <input placeholder='nimi'
               name="fullName"
               value={form.fullName} 
               className='authPageInput'
               onChange={changeHandler} />
        <input placeholder='tour'
               name="tour"
               value={form.tour} 
               className='authPageInput'
               onChange={changeHandler} /> 
        <input placeholder='piirkond'
               name="piirkond"
               value={form.piirkond} 
               className='authPageInput'
               onChange={changeHandler} />
        <select placeholder='auto tüüp' name="carType" value={form.carType} className='authPageInput' onChange={changeHandler}>
                <option value="Veok">Veok</option>
                <option value="Kaubik">Kaubik</option>
                <option value="Rekka">Rekka</option>
            </select> 
        <div className="buttonDiv">
        <button className="push_button" onClick={registerHandler}>registreerin</button>            
        </div>
    </div>
)}