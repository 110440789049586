import { useContext, useState, useEffect } from "react"
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext"
import { createKuuGrafik } from "./functions/createKuuGrafik";
import { getLastDayOfMonth } from "./functions/getLastDayOfMonth";

export function HomeKuuGrafik(){
    const { token } = useContext(AuthContext)
    const { request } = useHttp()
    let now = new Date()
    const [year, setYear] = useState(now.getFullYear())
    const [month, setMonth] = useState(now.getMonth()+1)
    let auth = useContext(AuthContext)
    let town = auth.town
    const getHandler = async() =>{
          try{
            const data = await request('/api/kuuGrafik/users', 'POST', {town, year, month},{
              Authorization: `Bearer ${token}`
            })
            let div = document.getElementById('NoGrafik')
            let table = document.getElementById('tableInner')
            if(data.grafik !== null){
            div.innerHTML = '';
            let days = getLastDayOfMonth(year, month)
            createKuuGrafik(data.ownersArray, days, year, month-1, town, false, data.grafik)  
            table.style.display = ''
            }
            if(data.grafik === null){
              div.innerHTML = `grafik puudu ${town} ${month} kuu ${year} aasta <br> график отсутствует ${town} ${month} месяц ${year} год`
              div.className = 'alarm'
              table.style.display = 'none'
            }
          } catch(e){
            alert(e.message)
          }
    }
    useEffect(()=>{getHandler()},[month])
    function previousMonth(){
        if(month===1){setMonth(12); setYear(year-1)}        
        else setMonth(month - 1)
    }
    function nextMonth(){
        if(month===12){setMonth(1); setYear(year+1)}        
        else setMonth(month + 1)
    }
    return(
    <>
    <div id="NoGrafik"/>
       <div className="buttonsDiv"> 
        <button className="push_button" onClick={previousMonth}>eelmine kuu</button>
        <button className="submitButton push_button" onClick={nextMonth}>jargmine kuu</button>         
      </div>
      <div className="TableInner marginleft marginTop" id="tableInner">
        <div className="table">
            <table className="homeKuuGrafik">
                <thead id="thead" />
                <tbody id="tbody" />
            </table>
      </div>
       <div className="graafikuPDiv">
        <p className="graafikuP">P = puhkus</p>
        <p className="graafikuP">Õ = õpib ringi</p>
        <p className="graafikuP">A = asendaja</p>
        <p className="graafikuP">V = vaba</p>
        <p className="graafikuP">H = haige</p>
        <p className="graafikuP">L = lisa tuur</p>
        <p className="graafikuP">S = skandinaavia</p>
        <p className="graafikuP">E = euroopa</p>
        <p className="graafikuP">KV = kaitseväe õppus</p>
        <p className="graafikuP">K = koolitus</p>
        <p className="graafikuP">LP = lapsehooldus puhkus</p>
        <p className="graafikuP">M = muu töö</p>
        <p className="graafikuP">W = würth ring</p>
        <p className="graafikuP">VA = vaip</p>
        <p className="graafikuP">VV = vastavalt vajadusele</p>
        <p className="graafikuP">AR = arst</p>
       </div>
    </div>
    </>
    );
}