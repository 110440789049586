import React, {useEffect ,useState} from "react"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Navbar } from '../components/navbar'
import  strelki from '../images/strelki.png'

export function Kataloog() {
  const [language, setLanguage] = useState("est");
  const navigate = useNavigate();
  let location = useLocation();
  const [hidden, setHidden] = useState(false)
  
  function changeLanguage(){
    let value = document.getElementById('language').value
    setLanguage(value)
  }
  useEffect(()=>{
    let newLocation = location.pathname.slice(0, -3)
    if(newLocation.length>9){
    navigate(`${newLocation}${language}`)
  }}, [language, location.pathname, navigate])
  function makeHidden(){
    setHidden(hidden => !hidden)
  }
  useEffect(()=>{
    let menu = document.getElementById('menuList')
    let strelkiButton = document.getElementById('strelkiButton')
    if(window. innerWidth<= 800){      
      if(!hidden){
      menu.style.display = 'none'
      strelkiButton.style.transform = 'none'
    }
    if(hidden){
      menu.style.display = 'flex'
      strelkiButton.style.transform = 'rotate(180deg)'
    }
    }
  }, [hidden])
    return(
        <div className='container'>
          <div className='menu'>
            {<Navbar />}
            <select onChange={changeLanguage} id="language" className="language">
              <option value="est">Est</option>
              <option value="rus">Ru</option>
            </select> 
           </div>    
          <div className="menuListInner"> 
            <div className="menuListDiv">
              <button onClick={makeHidden} id="strelkiButton" className="strelkiButton">
                <img className="strelki" alt="" src={strelki}></img>
              </button>  
                <div id="menuList" className="menuList">
                    <NavLink to={`/Kataloog/Wurth/${language}`} onClick={()=>setHidden(false)} className="adminLink menuListLink">Würth õhtune tour</NavLink>
                    <NavLink to={`/Kataloog/Orebro/${language}`} onClick={()=>setHidden(false)} className="adminLink menuListLink">Rootsi Örebro liin</NavLink>
                </div>
            </div>    
          <div className="kataloogOutlet"><Outlet /></div>
          </div>  
        </div>
    )}