import React , {useContext, useEffect, useState} from "react";
import { NavLink, useNavigate} from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import  menu  from "../images/menu.png"

export function Navbar(){
    const history = useNavigate()
    const auth = useContext(AuthContext)
    const [on, setOn] = useState(false)

    const logoutHandler = () => {
        auth.logout()
        history('/AuthPage')
    }

    useEffect(()=>{
    const adminLink = document.getElementById('adminPageLink') 
       if(!auth.isAdmin){
        adminLink.style.display = "none";
    }})
    function changeDisplay(){
        let navbar = document.getElementById('navbar')
        let screenWidth = window.innerWidth
        if(screenWidth <= 520){
        if(on === true){
            navbar.style.display = 'grid'
            document.addEventListener("click", ()=>setOn(false), "once")
        }
        else navbar.style.display = 'none'
    } else return}
    useEffect(()=>{
        changeDisplay()
    }, [on])
    return(
    <div className="navbarDiv">
        <div className="navbarimgdiv">
            <img alt="" className="navbarimg" id="navbarimg" onClick={()=>setOn(true)} src={menu}/>
        </div>
        <div className="navbar" id="navbar">
            <div id='adminPageLink' className="navbarLinkDiv"><NavLink className="navbarLink" to='/Admin'> AdminPage</NavLink></div>
            <div className="navbarLinkDiv"><NavLink className="navbarLink" to='/Home/HomeWorkGrafik'> HomePage</NavLink></div>
            <div className="navbarLinkDiv"><NavLink className="navbarLink" to='/Photo'> PhotoPage</NavLink></div>
            <div className="navbarLinkDiv"><NavLink className="navbarLink" id='kataloog' to='/Kataloog'>Kataloog</NavLink></div>
            <div className="navbarLinkDiv"><NavLink className="navbarLink" to='/AuthPage' onClick={logoutHandler}>Logout</NavLink></div>            
        </div>
    </div>    
    );
}