import ringsButton from '../../images/ringsButton.png'
export function CreateKuuGrafikButton(x){
    let ferstButton = document.createElement('button')
    let img = document.createElement('img')
    let div = document.createElement('div')
    let deleteButton = document.createElement('button')
    let hideButton = document.createElement('button')
    function hidden(div){
        div.className = 'ButtonsHiddenDiv'
    }
    function visible(div){
        div.className = 'ButtonsHiddenDiv1'
        document.addEventListener("click", ()=>hidden(div), "once")
    }
    function deleteRow(div){
        let row = div.parentNode.parentNode
        row.parentElement.removeChild(row)
    }
    function hideRow(div){
        let row = div.parentNode.parentNode
        if(row.classList.contains("hiddenRow")){
           row.classList.remove("hiddenRow")
        }
        else {row.classList.add("hiddenRow")}
    }
    ferstButton.className = 'ferstButton'
    ferstButton.onclick = () => visible(div)
    img.src = ringsButton
    img.alt = ''
    img.className = 'ringsButton'
    div.className = 'ButtonsHiddenDiv'
    deleteButton.textContent = 'Delete'
    deleteButton.className = 'hiddenButtons'
    deleteButton.onclick = () => deleteRow(div)
    hideButton.textContent = 'Hide'
    hideButton.className = 'hiddenButtons'
    hideButton.onclick = () => hideRow(div)
    div.append(deleteButton)
    div.append(hideButton)
    ferstButton.append(img)
    x.prepend(div)
    x.prepend(ferstButton)
}