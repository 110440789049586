import React , {useContext, useEffect, useState} from "react";
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext";
import { createKuuGrafik } from "./functions/createKuuGrafik";
import { getLastDayOfMonth } from "./functions/getLastDayOfMonth";
import { addNewTableRow } from "./functions/addNewTableRow";
import { saturdayComponents } from "./functions/saturdayComponent";

export function AdminkuuGrafik(){
    const { token } = useContext(AuthContext)
    const { request } = useHttp()
    const [town1, setTown] = useState(null)
    const [year1, setYear] = useState(null)
    const [month1, setMonth] = useState(null)
    const [days1, setDays] = useState(null)
    const [visible, setVisible] = useState(false)
    let dataArray = []
    let hiddenUsers = []

    async function getHandler(){
      document.getElementById('table').className = "table";
      let town = document.getElementById('town').value
      let year = document.getElementById('year').value
      let month = document.getElementById('month').value    
        try{
          const data = await request('/api/kuuGrafik/users', 'POST', {town, year, month},{
            Authorization: `Bearer ${token}`
          })
          let days = getLastDayOfMonth(year, month)
          createKuuGrafik(data.ownersArray, days, year, month-1, town, true, data.grafik)
          setTown(town); setMonth(month); setYear(year); setDays(days);
          setVisible(true)
          saturdayComponents(data.links)
        } catch(e){
          alert(e.message)
        }}
        function getInputsValue(){
          let trs = document.querySelectorAll('#row2')
          for(let i = 0; i < trs.length; i++){
          let array = []
          let hiddenUser = []
          let name = trs[i].cells[0].value
          let classContains = trs[i].classList.contains("hiddenRow")
          if(name !== null && name !== undefined){
            classContains ? hiddenUser.push(name) : array.push(name)
          }
          if(name === null || name === undefined){
            let trsInput = trs[i].cells[0].firstChild.value
            name = trsInput
            classContains ? hiddenUser.push(name) : array.push(name)
          }
            for(let j = 1; j < trs[i].cells.length; j++){
              if(classContains){
              hiddenUser.push(trs[i].cells[j].firstChild.value)
              }
              if(!classContains){
              array.push(trs[i].cells[j].firstChild.value)
              }
            }
          classContains ? hiddenUsers.push(hiddenUser) : dataArray.push(array)
        }
      }
        async function sendNewKuuGrafik(){  
          getInputsValue()
          try{
            const data = await request('/api/kuuGrafik/createKuuGrafik', 'POST', { hiddenUsers, dataArray, town1, year1, month1 },{
              Authorization: `Bearer ${token}`
            })
            if(data) alert('valmis')
          } catch(e){
            alert(e.message)
          }
        }
        useEffect(()=>{
          if(visible){document.getElementById('buttonsDiv').style = 'display: block'}
          if(!visible){document.getElementById('buttonsDiv').style = 'display: none'}
        })
    async function changeKuuGrafik(){
      getInputsValue()
      try{
        const data = await request('/api/kuuGrafik/changeKuuGrafik', 'POST', { hiddenUsers, dataArray, town1, year1, month1 },{
          Authorization: `Bearer ${token}`
        })
        if(data) alert(`valmis`)
      } catch(e){
        alert(e.message)
      }
    }
    function newRow(){
      addNewTableRow(days1, month1-1, year1)
    }

    return(
    <>
    <div className="registerInputs inputsDiv">
        <select id="town" className='authPageInput'>
            <option value="Tallinn">Tallinn</option>
            <option value="Rakvere">Rakvere</option>
            <option value="Jõhvi">Jõhvi</option>
            <option value="Tartu">Tartu</option>
        </select>
        <input placeholder="aasta"
                    className='authPageInput'
                    type= "number"
                    id="year" />
        <input placeholder="kuu"
                    className='authPageInput'
                    type= "number"
                    id="month" />
                <div className="buttonDiv">
              <button onClick={getHandler} className="push_button"> Näita </button>
        </div>
    </div>
    <div className="TableInner">
        <div id="table">
        <table className="homeKuuGrafikTable">
            <thead id="thead" />
            <tbody id="tbody" />
        </table>
        </div>
    </div >
    <div className="buttonsDiv" id="buttonsDiv">
      <div><button className="push_button" onClick={sendNewKuuGrafik}>salvesta</button></div>
      <div><button className="submitButton1 push_button" onClick={changeKuuGrafik}>muuta</button></div>
      <div><button className="submitButton2 push_button" onClick={newRow}>lisa rida</button></div>
    </div>
    <div className="saturdayDiv" id="saturdayDiv" />
    </>
    );
}