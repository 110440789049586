import React, {useContext, useState, useEffect} from "react"
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext"
import { createTable } from "./functions/createTable"
import { NewTable } from "./functions/newTable"
import { getLastDayOfMonth } from "./functions/getLastDayOfMonth"
import logo from "../images/strongway.png"

export function HomeWorkGrafik() {
    const { token } = useContext(AuthContext)
    const { request } = useHttp()
    const [year, setYear] = useState(new Date().getFullYear()) //
    const [month, setMonth] = useState(new Date().getMonth() + 1) //
    let newTableForm = { year, month }
    const [form, setForm] = useState({
        day: `${new Date().getDate()}`, month: `${new Date().getMonth() + 1}`, year: `${new Date().getFullYear()}`,
        carNumber: '', startKm: '', finishKm: '', clients: '', start: '', finish: '', tour: '', pakk: ''})
      const changeHandler = event =>{
          setForm({...form, [event.target.name]: event.target.value})
        }
      const pressHandler = async event => {
            try{
                if(form.day <=0 || form.day > 31 || form.month > 12 || form.month < 1) 
                return alert("Kontrollige kuupäev!");
                if((form.finishKm - form.startKm) < 0 || form.clients < 0)
                return alert("Kontrollige andmed!");
                const data = await request('/api/link/generate', 'POST', { ...form },{
                    Authorization: `Bearer ${token}`
                  })
                if (data) alert('valmis')
                window.location.reload();
            } catch(e){
                alert(e.message)
            }
        }
        const changeTableHandler = async() =>{
            try{
              if(form.carNumber === "" && form.clients === "" && form.finish === "" && form.finishKm === "" &&
              form.pakk === "" && form.start === "" && form.startKm === "" && form.tour === ""){
              let answer = window.confirm("soovite eemaldada päeva andmed?")
                if(answer){
                  await request('/api/link/delete', 'POST', {...form},{
                    Authorization: `Bearer ${token}`
                  })
                  window.location.reload()
                  }
                }
              else{
               const data = await request('/api/link/change', 'POST', {...form},{
                Authorization: `Bearer ${token}`
              })
              if (data) alert('andmed muudatud!')
              window.location.reload()
              }
            } catch(e){
                alert(e.message)
            }
          }
          async function createTableOnсe(){
            let inner = document.getElementById('TableInner')
            inner.innerHTML = ''        
            const newTabledata = await request('/api/link/get', 'POST', newTableForm ,{
                Authorization: `Bearer ${token}`
              })
              if(newTabledata.length < 1){
              inner.innerHTML = `selle kuu andmed puuduvad./ <br> Данные за этот месяц отсутствуют.`
              inner.classList.add('alarm')
              }
              else if(newTabledata.length >= 1){
              inner.classList.remove('alarm')
              let pakk = newTabledata.find(item => item.pakk)
              let days = getLastDayOfMonth(year, month)
              createTable(1, days, year, month -1, pakk).then(NewTable(newTabledata, year, 1))
              }
              else return;
        }
       useEffect(() =>{
          createTableOnсe()
        }, [month])
        function previousMonth(){
          if(month === 1){
            setMonth(12)
            setYear(year - 1)
          }
          else{
            setMonth(() => month - 1)
          }
        }
        function nextMonth(){
          if(month === 12){
            setMonth(1)
            setYear(year + 1)
          }
          else{
            setMonth(month + 1)
          }
        }

    return(
      <>
                <div className="homeContainer">
                    <div  className="inputsDiv">
                        <div className="TimeInputs">
                        <div className="inputInner">
                        <h5  className="inputName">päev</h5>
                        <input type="Number" 
                        max="31"
                        className="inputNameDate" 
                        name='day' 
                        value={form.day} 
                        id="day" 
                        onChange={changeHandler} />
                        </div>
                        <div className="inputInner">
                        <h5  className="inputName">kuu</h5>
                        <input type="Number" 
                        max="12"
                        className="inputNameDate" 
                        name='month' 
                        value={form.month} 
                        id="month" 
                        onChange={changeHandler} />
                        </div>
                        <div className="inputInner">
                        <h5  className="inputName">aasta</h5>
                        <input type="Number" 
                        className="inputNameDate" 
                        name='year' 
                        value={form.year}
                        id="year" 
                        onChange={changeHandler} />
                        </div>
                        </div>
                        <div className="TimeInputs">
                        <div className="aegInput">
                        <h5 className="inputName">tuur</h5>
                        <input
                        className="inputNameDate" 
                        name="tour"
                        value={form.tour.startsWith("0")?form.tour.substring(1):form.tour}
                        id="tour"
                        maxLength="3"
                        onChange={changeHandler} />
                        </div>
                        <div className="aegInput">
                        <h5 className="inputName">stoppid</h5>
                        <input type="number" 
                        className="inputNameDate" 
                        name="clients" 
                        value={form.clients}
                        id="clients" 
                        onChange={changeHandler} />
                        </div>
                        <div className="aegInput">
                        <h5 className="inputName">pakkid</h5>
                        <input type="number" 
                        className="inputNameDate" 
                        name="pakk" 
                        value={form.pakk}
                        id="pakk" 
                        onChange={changeHandler} />
                        </div>
                        </div>
                        <div className="aegInput">
                        <h5  className="inputName">auto number</h5>
                        <input type="string" 
                        className="inputs" 
                        name='carNumber' 
                        value={form.carNumber} 
                        id="CarNumber" 
                        onChange={changeHandler} />
                        </div>
                        <h5  className="inputName">odometer algus</h5>
                        <input type="number" 
                        className="inputs" 
                        name='startKm' 
                        value={form.startKm}
                        id="Startkm" 
                        onChange={changeHandler} />
                        <h5  className="inputName">odometer lõpp</h5>
                        <input type="number" 
                        className="inputs" 
                        name='finishKm' 
                        value={form.finishKm}
                        id="Finishkm" 
                        onChange={changeHandler} />
                    
                        <div className="TimeInputs">
                        <div className="aegInput">
                        <h5 className="inputName"> algus</h5>
                        <input type="time"
                        className="inputsaeg" 
                        name="start"
                        value={form.start}
                        id="timeStart" 
                        onChange={changeHandler} />
                        </div >
                        <div className="aegInput">
                        <h5 className="inputName">lõpp</h5>
                        <input type="time" 
                        className="inputsaeg" 
                        name="finish"
                        value={form.finish}
                        id="finish" 
                        onChange={changeHandler} />
                        </div>
                        </div>
                        <div className='buttonDiv'>
                          <button className="push_button" onClick={pressHandler} >saada</button>
                          <button className="submitButton push_button" onClick={changeTableHandler} >muuta</button>                       
                        </div>
                </div>
                <img className="logoHome" src={logo} alt='logo' />     
                </div>
                        <div className="buttonsDiv"> 
                          <button className="push_button" onClick={previousMonth}>eelmine kuu</button>
                          <button className="submitButton push_button" onClick={nextMonth}>jargmine kuu</button>         
                        </div>     
                        <div>            
                  <div className="TableInner marginleft" id="TableInner" />
                        </div>  
                </>
    )}