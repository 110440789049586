import React, {useContext, useState, useEffect} from "react"
import { Outlet, NavLink} from "react-router-dom"
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/AuthContext"
import { Navbar } from '../components/navbar'

export function HomePage() {
    const { request } = useHttp()
    const { token } = useContext(AuthContext)
    const x = <span className="variable"></span>
    const [variable, setVariable] = useState(null)
    async function getHandler(){
    try{
        const data = await request('/api/kuuGrafik/get', 'POST', null,{
            Authorization: `Bearer ${token}`
          }) 
        if(data.variable) return
        if(!data.variable){setVariable(x)} 
      } catch(e){
        alert(e.message)
      }        
    }
    useEffect(()=>{
        getHandler()
    }, [])
    return(
        <div className='container'>
            <div className='menu'>
                {<Navbar />}
            </div>
                <div className="NavLinkMenu">
                <NavLink to="/Home/HomeWorkGrafik" className="adminLink"> tehtud töö </NavLink>
                <NavLink to="/Home/HomeKutuseGrafik" className="adminLink"> kütusegrafik </NavLink>
                <NavLink to="/Home/HomeKuuGrafik" className="adminLink" onClick={()=>setVariable(null)}> kuu graafik {variable}</NavLink>
                </div>
                <div className="outletPlace">
                  <Outlet />
                </div>    
        </div>
    )}