import { getSunday } from './getSunday'
import { getKuuInput } from './getKuuInput'
import { CreateKuuGrafikButton } from './createKuuGrafikButton'
export function createKuuGrafik(names, days, year, month, town, admin, grafik){
    let thead = document.getElementById('thead')
    let tbody = document.getElementById('tbody')
    thead.innerHTML = ''
    tbody.innerHTML = ''

    //первая строка
    let row = document.createElement('tr')
    let heading1 = document.createElement('th')
    let piirkond1 = document.createElement('th')
    row.className = "row"
    row.id = `row`
    heading1.innerHTML = `${town} ${month+1}.${year}`
    heading1.classList.add('ferst')
    piirkond1.innerHTML = 'Piirkond'
    row.appendChild(heading1)
    row.appendChild(piirkond1)
    thead.appendChild(row)
    for(let i = 1; i <=days; i++){
        let heading = document.createElement('th')
        heading.innerHTML = i
        heading.className = 'kuuGrafikTd'
        if(i<=9){heading.innerHTML =`0${i}`}
        if(i===32){heading.innerHTML = ''}
        row.appendChild(heading)
        thead.appendChild(row)
        getSunday(year, month, i, heading)
    };

    //остальная таблица (если график ранее не был создан)
    if(grafik === null){
        for(let i = 0; i < names.length; i++){
        let tourInput = document.createElement('input')
        let row2 = document.createElement('tr')
        let heading2 = document.createElement('th')
        let piirkond2 = document.createElement('td')
        let input = document.createElement('input')
        tourInput.className = 'tourInput'
        tourInput.onchange = getKuuInput;
        tourInput.placeholder = `${names[i][2]}`
        row2.id = 'row2'
        heading2.innerHTML = `<span>${names[i][0]}<span/>`
        heading2.value = `${names[i][0]}`
        heading2.id = `${names[i][0]}`
        heading2.classList.add('ferst')
        input.className = 'kuuGrafikInput'
        input.value = `${names[i][3].piirkond}`
        piirkond2.className = 'kuuGrafikTd'
        piirkond2.appendChild(input)
        if(admin){
            heading2.appendChild(tourInput)
            CreateKuuGrafikButton(heading2)
        }
        row2.appendChild(heading2)
        row2.appendChild(piirkond2)
        tbody.appendChild(row2);
        for(let j = 1; j <= days; j++){
            let td = document.createElement('td')
            td.className = 'kuuGrafikTd'
            let input = document.createElement('input')
            input.className = 'kuuGrafikInput'
            td.appendChild(input)
            row2.appendChild(td)
            getSunday(year, month, j, input)
            getSunday(year, month, j, td)
            }
        }
    }
    //остальная таблица (если в базе данных уже есть график)

    if(grafik !== null){
    let grafikArray = grafik.array
    let notActiveUsersgrafik = grafik.notActiveUsersArray
    function otherTableInner(grafik, x){
        for(let i = 0; i < grafik.length; i++){
            let tourInput = document.createElement('input')
            let row2 = document.createElement('tr')
            let heading2 = document.createElement('th')
            let piirkond2 = document.createElement('td')
            let input = document.createElement('input')
            tourInput.className = 'tourInput'
            tourInput.onchange = getKuuInput;
            row2.id = 'row2'
            piirkond2.className = 'kuuGrafikTd'
            if(!x){
                row2.classList.add("hiddenRow")
            }
            heading2.innerHTML = `<span>${grafik[i][0]}<span/>`
            heading2.value = `${grafik[i][0]}`
            heading2.id = `${names[i][0]}`
            heading2.classList.add('ferst')
            input.className = 'kuuGrafikInput'
            if(admin){
                heading2.appendChild(tourInput)
                input.value = `${grafik[i][1]}`
                piirkond2.appendChild(input)
                CreateKuuGrafikButton(heading2)
            }
            if(!admin){
                piirkond2.innerHTML = `${grafik[i][1]}`
            }
            row2.appendChild(heading2)
            row2.appendChild(piirkond2)
            tbody.appendChild(row2);
            for(let j = 1; j <= days; j++){
                let td = document.createElement('td')
                td.className = 'kuuGrafikTd'
                let input = document.createElement('input')
                input.className = 'kuuGrafikInput'
                if(admin){
                    input.value = grafik[i][j+1]
                    td.appendChild(input)
                }
                if(!admin){td.innerHTML = `${grafik[i][j+1]}`}
                row2.appendChild(td)
                getSunday(year, month, j, input)
                getSunday(year, month, j, td)
                }
        }
    }
        otherTableInner(grafikArray, true)
    if(notActiveUsersgrafik !== null && notActiveUsersgrafik !== undefined && admin){
        otherTableInner(notActiveUsersgrafik, false)
    }
   }
}